html {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.card-holder {
  display: flex;
  justify-content: space-around;
}
.brand-border {
  border: 1px solid rgba(245, 245, 245, 0.534);
  border-radius: 5px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}
.error-holder {
  border: 1px solid rgba(245, 245, 245, 0.534);
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  max-width: 500px;
  margin: 5rem auto;
}
ul {
  list-style: none;
  padding: 0;
}
.img-holder {
  display: flex;
  gap: 0.5rem;
}

.jumbotron-main {
  background: url('./assets/images/jumbotron-img.jpg') no-repeat center center;
  background-size: cover;
  margin-top: 7rem;
}
.jumbotron-main img {
  border-radius: 5px;
}
.table {
  margin-top: 7rem;
}
.card {
  border-color: rgba(245, 245, 245, 0.534);
  width: 22rem;
}
.ul-smallScreen {
  margin-top: 7rem;
  text-align: center;
  line-height: 3rem;
}

@media screen and (max-width: 790px) {
  .card-holder {
    flex-direction: column-reverse;
    align-items: center;
    gap: 5rem;
  }
  .img-holder {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .card {
    width: 16rem;
  }
}
